import SlidingPane from "react-sliding-pane";
// import labels from "../../../translationData/menu.json";
import { useContext, useState } from "react";
import { FaAngleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import OffcanvasSideBar from "./OffcanvasSideBar";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { GetAllCategoryAPI } from "../../../API/CategoryAPI";

import getTranslation from "../../../Helper/getTranslationUtility";
import labels from "../../../translationData/menu.json";
import { LanguageContext } from "../../../Context/LanguageContext";

const SideBar = ({ SetpanLeft, paneLeft }) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const toggleOffcanvas = () => {
    setShow(!show);
  };
  const handleCloseSidebar = () => setShow(false);

  // const [currentLanguage, setCurrentLanguage] = useState("en");
  const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);
  // Function to change the language
  const changeLanguage = (language) => {
    setCurrentLanguage(language);
  };

  useEffect(() => {
    GetAllCategoryAPI();
  }, []);
  let data = useSelector((state) => state.category.allCategoryList);
  return (
    <>
      <div style={{ zIndex: 999 }}>
        <SlidingPane
          className="slidingpaneLeft"
          isOpen={paneLeft}
          from="left"
          width="320px"
          z-Index="27"
          onRequestClose={() => SetpanLeft(false)}
        >
          <div className="d-flex">
            <div className="row">
              <div className="col-md-10">
                <a href="https://ownfood.net">
                  {" "}
                  <img
                    src={"/Assets/Img/ownfood.png"}
                    className="img-fluid"
                    alt=""
                  />
                </a>
              </div>
              <div className="col-md-2">
                {" "}
                <button
                  class=""
                  onClick={() => {
                    SetpanLeft(false);
                  }}
                >
                  <i class="icofont-close"></i>
                </button>
              </div>
            </div>
          </div>

          <div className="sidebar overflow-hidden">
            <div>
              <div className="sidebar-item mt-5">
                <div className="sidebar-title">
                  <span>
                    {/* {labels.earnMoney.bn} */}
                    {getTranslation("earnMoney", currentLanguage, labels)}
                  </span>
                </div>
              </div>

              <div className="sidebar-item">
                <div className="sidebar-title" onClick={handleShow}>
                  <span>
                    {/* {labels.category.bn} */}
                    {getTranslation("category", currentLanguage, labels)}
                  </span>
                  <FaAngleRight size={25} />
                </div>
              </div>

              <div className="sidebar-item">
                <Link
                  to={"https://ownfood.net/Category/64da6841bd44061552443a53"}
                  className="d-block"
                >
                  <div className="sidebar-title">
                    <span>
                      {/* {labels.catering.bn} */}
                      {getTranslation("catering", currentLanguage, labels)}
                    </span>
                  </div>
                </Link>
              </div>

              <div className="sidebar-item">
                <Link to={"/AllRecipe"} className="d-block">
                  <div className="sidebar-title">
                    <span>
                      {/* {labels.recipe.bn} */}
                      {getTranslation("recipe", currentLanguage, labels)}
                    </span>
                  </div>
                </Link>
              </div>

              {/* <div className="sidebar-item">
                <div className="sidebar-title">
                  <span>
                    
                    {getTranslation("diary", currentLanguage, labels)}
                  </span>
                </div>
              </div> */}
              
            </div>
          </div>
        </SlidingPane>
      </div>

      <OffcanvasSideBar
        show={show}
        toggleOffcanvas={toggleOffcanvas}
        handleCloseSidebar={handleCloseSidebar}
        data={data}
      />
    </>
  );
};

export default SideBar;
