import { Button, Col, Container, Offcanvas, Row } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
const OffcanvasSideBar = ({
  show,
  toggleOffcanvas,
  handleCloseSidebar,
  data,
}) => {
  return (
    <>
      {" "}
      <Offcanvas
        show={show}
        toggleOffcanvas={toggleOffcanvas}
        onHide={handleCloseSidebar}
        style={{ width: 319 }}
      >
        <Offcanvas.Header>
          <Offcanvas.Title>
            <div className='d-flex align-items-center ms-4'>
              <div onClick={toggleOffcanvas}>
                {" "}
                <FaArrowLeft />
              </div>
              <div className='ms-5'>
                {" "}
                <h4>Main Menu</h4>
              </div>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {data.map((item) => (
            <div className='sidebar-item'>
              <div className='sidebar-title'>
                {" "}
                <Link to={`/Category/${item?._id}`}>{item.categoryName}</Link>
              </div>
            </div>
          ))}
          {/* <div className='secoundTitle'> ALL FOOD</div> */}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default OffcanvasSideBar;
