import { FeaturedVideo } from "@mui/icons-material";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const items =
  localStorage.getItem("cartList") !== null
    ? JSON.parse(localStorage.getItem("cartList"))
    : [];
const initialState = {
  cartItems: items,
};
const User = JSON.parse(localStorage.getItem("UserDetails"));
export const addItem = createAsyncThunk(
  "cart/addItem",
  async (newItem, { getState, dispatch }) => {
    const { cartItems } = getState().cart;
    //  const state = getState().cart;
    console.log("cartSlice", newItem);
    const foodStock = newItem.foodQty;
    console.log("foodStock:", foodStock);
    let existingItem = cartItems.find((item) => item._id === newItem._id);
    const sellerExists = cartItems.find(
      (item) => item.sellerID === newItem.sellerID
    );

    const sameFoodTypeExists = cartItems.find(
      (item) => item.foodType === newItem.foodType
    );
    const existingItemIndex = cartItems.findIndex(
      (item) => item._id === newItem._id
    );
    console.log("cartSlice", 0, existingItem);
    if (existingItemIndex !== -1) {
      const existingItem = cartItems[existingItemIndex];
      // const inCartQty = existingItem.foodQty;
      let requestQty = existingItem.foodQty + 1;
      const foodStock = existingItem.foodStock;
      if (foodStock > 0 && requestQty > foodStock) {
        requestQty = foodStock;
        await Swal.fire({
          title: "You have reached maximum food quantity.",
        });
      }
      const updatedItem = {
        ...existingItem,
        foodQty: requestQty,
      };
      console.log("foodReq", requestQty);
      console.log("cartSlice", 1, updatedItem);
      // Update the item in the cart
      return { index: existingItemIndex, item: updatedItem };
    } else if (cartItems.length > 0 && !sellerExists) {
      console.log("cartSlice", 2);
      await Swal.fire({
        title: "Are you Remove your Previous food?",
        showCancelButton: true,
        confirmButtonText: "Save",
      }).then(async (result) => {
        console.log("cartSlice", 3);
        if (result.isConfirmed) {
          console.log("cartSlice", 4);

          dispatch(removeAllItems());
          console.log("cartSlice", 5);

          dispatch(addItemLocally(newItem));

          //  return getState().cart.cartItems;
        } else {
          console.log("cartSlice", 6);
          console.log("cartSlice", cartItems);
          return [...cartItems];
          //  return;
        }
      });
    } else if (cartItems.length > 0 && !sameFoodTypeExists && sellerExists) {
      console.log("cartSlice", 2);
      await Swal.fire({
        title:
          "don't buy another type food at a time.please remove previous data",
        showCancelButton: true,
        confirmButtonText: "Save",
      }).then(async (result) => {
        console.log("cartSlice", 3);
        if (result.isConfirmed) {
          console.log("cartSlice", 4);

          dispatch(removeAllItems());
          console.log("cartSlice", 5);

          dispatch(addItemLocally(newItem));

          //  return getState().cart.cartItems;
        } else {
          console.log("cartSlice", 6);
          console.log("cartSlice", cartItems);
          return [...cartItems];
        }
      });
    } else {
      let requestQty = 1;
      const foodStock = newItem.foodQty;
      if (foodStock > 0 && requestQty > foodStock) {
        requestQty = foodStock;
      }
      return {
        index: -1,
        item: { ...newItem, foodQty: requestQty, foodStock: foodStock },
      };
    }

    //  if (existingItem) {
    //    console.log("Existing item foodQty before:", existingItem.foodQty);
    //    const updatedFoodQty = existingItem.foodQty + 1;
    //    console.log("Updated foodQty:", updatedFoodQty);
    //    for (let i = 0; i < state.length; i++) {
    //      if (state[i]._id === newItem._id) {
    //        items[i].foodQty += 1;
    //        console.log(items[i].foodQty);
    //      }
    //    }
    //    return state;
    //  } else if (!existingItem) {
    //    console.log(3);

    //    if (!sellerExists && state.length > 0) {
    //      console.log(4);
    //      return { ...newItem, foodQty: 1 };
    //    } else {
    //      console.log(5);
    //      return { ...newItem, foodQty: 1 };
    //    }
    //  }
  }
);
const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItemLocally: (state, action) => {
      state.cartItems.push({ ...action.payload, foodQty: 1 });
    },
    setCartList: (state, action) => {
      console.log("click-set-1");
      state.cartItems = action.payload;
    },
    removeAllItems: (state) => {
      console.log(state.cartItems, "cartSlice");
      state.cartItems = [];
    },
    removeItem: (state, action) => {
      const itemId = action.payload;
      console.log(state, action, "cartSlice");
      let removeData = state.cartItems.filter((item) => item._id !== itemId);
      console.log(removeData, "cartSlice");
      state.cartItems = removeData;
    },

    decreaseItem: (state, action) => {
      const itemId = action.payload._id;
      const existingItemIndex = state.cartItems.findIndex(
        (item) => item._id === itemId
      );
      const existingItem = state.cartItems.find((item) => item._id === itemId);
      if (existingItem) {
        if (existingItem.foodQty > 1) {
          existingItem.foodQty--;
        } else {
          state.cartItems.splice(existingItemIndex, 1);
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addItem.pending, (state, action) => {
      console.log(state);
    });
    builder.addCase(addItem.fulfilled, (state, action) => {
      console.log("cartSlice", action.payload, action);
      const { index, item } = action.payload;
      if (index !== -1) {
        console.log("cartSlice", 11);

        state.cartItems[index] = item;
      } else {
        console.log("cartSlice", 12);

        state.cartItems.push(item);
      }

      //  action.payload;
    });
    builder.addCase(addItem.rejected, (state, action) => {
      console.log(state);
    });
  },
});
export const {
  removeItem,
  setCartList,
  removeAllItems,
  addItemLocally,
  decreaseItem,
} = cartSlice.actions;
export default cartSlice.reducer;
