import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import FoodPresentationCard from "../Cards/FoodPresentation/FoodPresentationCard";
import { Container } from "react-bootstrap";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import axios from "axios";
import { BaseURL } from "../../../Helper/config";

const SliderProvider = ({
  sliderSettings,
  cardComponent: CardComponent,
  title,
  food,
}) => {
  // const [food, setFood] = useState([]);
  const getFood = async () => {
    const data = await axios.get(`${BaseURL}/get-food`);
    console.log(data.data.data);
  };
  useEffect(() => {
    getFood();
  }, []);
  const settings = {
    infinite: true,
    speed: 500,
    autoplaySpeed: 5000,
    slidesToShow: 4,
    nextArrow: <FaArrowRight />,
    prevArrow: <FaArrowLeft />,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  let customizeSetting = { ...settings };
  if (!!food && food.length < 4) {
    customizeSetting = {
      ...customizeSetting,
      slidesToShow: food.length,
    };
  }

  const combinationSettings = { ...settings, ...sliderSettings };
  const finalSettings = { ...combinationSettings, ...customizeSetting };
  return (
    <Container
      className='InstantFood home-index-slider slider-arrow
  '
    >
      <div className='row  '>
        <div className='col'>
          <div className='section-heading'>
            <div className='headerText d-flex justify-content-start'>
              <div className='text-left '>
                <h2>
                  <span className='sf_init_title'>{title}</span>
                  <span className='sf_text-theme'> Foods</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className='row'>
          <Slider className='row' {...finalSettings}>
            {food &&
              food.map((item, index) => (
                <CardComponent data={item} index={index} width={98} />
              ))}
          </Slider>
        </div>
      </div>

    
    </Container>
  );
};

SliderProvider.defaultProps = {
  cardComponent: FoodPresentationCard,
};

export default SliderProvider;
