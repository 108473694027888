import React from 'react';

const Loader = () => {
    return (
        <>
            <div className="spinner-container">
                <div role="status">
                <span className="loader"></span>
                </div>
            </div>

        </>
    );
};

export default Loader;