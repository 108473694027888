// import React, { useState, useEffect } from "react";
// import moment from "moment";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

// const TimeDisplay = ({ max }) => {
//   const [currentTime, setCurrentTime] = useState(moment());
//   const [timeSlots, setTimeSlots] = useState([]);
//   const [selectedDate, setSelectedDate] = useState(null);
//   const [selectedTime, setSelectedTime] = useState(null);
//   const skipHour = parseInt(max) || 2;

//   useEffect(() => {
//     const interval = setInterval(() => {
//       const now = moment();
//       setCurrentTime(now);
//     }, 1000);

//     return () => clearInterval(interval);
//   }, []);

//   // Effect to initialize default selected date and time
//   useEffect(() => {
//     const defaultDate = moment().startOf("day");
//     setSelectedDate(defaultDate);
//     setSelectedTime(moment(defaultDate).add(skipHour, "hours"));
//   }, [skipHour]);

//   // Function to handle date change in the DatePicker
//   const handleDateChange = (date) => {
//     const selectedDateMoment = moment(date).startOf("day");
//     setSelectedDate(selectedDateMoment);
//     setSelectedTime(moment(selectedDateMoment).add(skipHour, "hours"));
//   };

//   // Effect to update time slots when selectedDate or skipHour changes
//   useEffect(() => {
//     if (!selectedDate) return;

//     const today = moment().startOf("day");
//     const startOfSelectedDate = selectedDate.clone().startOf("day");
//     const endOfSelectedDate = selectedDate.clone().endOf("day");

//     let startHour = today.isSame(startOfSelectedDate)
//       ? moment().add(skipHour, "hours")
//       : startOfSelectedDate;

//     const slots = [];
//     let time = startHour.clone();

//     while (time.isSameOrBefore(endOfSelectedDate)) {
//       slots.push(time.clone());
//       time.add(1, "hour");
//     }

//     setTimeSlots(slots);
//   }, [selectedDate, skipHour]);

//   // Function to handle time change in the dropdown
//   const handleTimeChange = (e) => {
//     const selectedTimeString = e.target.value;
//     setSelectedTime(moment(selectedTimeString, "YYYY-MM-DD hh:mm A"));
//   };

//   return (
//     <div>
//       <h1>Current Time: {currentTime.format("hh:mm:ss A")}</h1>
//       <h1>
//         Selected Date and Time:{" "}
//         {selectedDate && selectedTime && (
//           <>
//             {selectedDate.format("YYYY-MM-DD")} {selectedTime.format("hh:mm A")}
//           </>
//         )}
//       </h1>

//       <label htmlFor='datePicker'>Select Date:</label>
//       <DatePicker
//         id='datePicker'
//         selected={selectedDate ? selectedDate.toDate() : null}
//         onChange={handleDateChange}
//         minDate={moment().add(skipHour, "hours").toDate()}
//       />
//       <br />
//       <label htmlFor='timeSlots'>Select Order Time:</label>
//       <select
//         id='timeSlots'
//         onChange={handleTimeChange}
//         value={selectedTime ? selectedTime.format("YYYY-MM-DD hh:mm A") : ""}
//       >
//         {timeSlots.map((slot, index) => (
//           <option key={index} value={slot.format("YYYY-MM-DD hh:mm A")}>
//             {slot.format("hh:mm A")}
//           </option>
//         ))}
//       </select>
//     </div>
//   );
// };

// export default TimeDisplay;
import React, { useState, useEffect } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./CartDateSelect.css";
import axios from "axios";
const TimeDisplay = ({
  max,
  selectedDate,
  setSelectedDate,
  selectedTime,
  setSelectedTime,
}) => {
  const [currentTime, setCurrentTime] = useState(moment());
  const [timeSlots, setTimeSlots] = useState([]);
  // const [selectedDate, setSelectedDate] = useState(moment().startOf("day"));
  // const [selectedTime, setSelectedTime] = useState(null);
  const skipHour = parseInt(max) || 3;
  console.log(skipHour);
  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment();
      setCurrentTime(now);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setSelectedTime(moment().add(skipHour, "hours"));
  }, [skipHour]);

  const handleDateChange = (date) => {
    setSelectedDate(moment(date).startOf("day"));
    setSelectedTime(moment(date).startOf("day").add(skipHour, "hours"));
  };

  useEffect(() => {
    if (!selectedDate) return;

    const today = moment().startOf("day");
    const startOfSelectedDate = selectedDate.clone().startOf("day");
    const endOfSelectedDate = selectedDate.clone().endOf("day");

    let startHour = today.isSame(startOfSelectedDate)
      ? moment().add(skipHour, "hours")
      : startOfSelectedDate;

    const slots = [];
    let time = startHour.clone();

    while (time.isSameOrBefore(endOfSelectedDate)) {
      slots.push(time.clone());
      time.add(1, "hour");
    }

    setTimeSlots(slots);
  }, [selectedDate, skipHour]);

  const handleTimeChange = (e) => {
    const selectedTimeString = e.target.value;
    setSelectedTime(moment(selectedTimeString, "YYYY-MM-DD hh:mm A"));
  };
  // const updateTime = async () => {
  //   await axios.post("/update-order/id", {
  //     deliveryDateTime: selectedDate + selectedTime,
  //   });
  // };
  // useEffect(() => {
  //   updateTime();
  // }, []);
  return (
    <div className='CartDateSelect'>
      <div className='row'>
        {/* <h1>{new Date(selectedDate + selectedTime)}</h1> */}
        <div className='col-4'>
          <label className='' htmlFor='datePicker'>
            Delivery on
          </label>
        </div>
        <div className='col-4'>
          <div>
            <DatePicker
              className='form-control '
              id='datePicker'
              selected={selectedDate ? selectedDate.toDate() : null}
              onChange={handleDateChange}
              minDate={moment().add(skipHour, "hours").toDate()}
              dateFormat='d ,MMMM'
            />
          </div>
        </div>
        <div className='col-4'>
          {/* <label htmlFor='timeSlots'>Select Order Time:</label> */}
          <select
            id='timeSlots'
            className='form-control'
            onChange={handleTimeChange}
            value={
              selectedTime ? selectedTime.format("YYYY-MM-DD hh:mm A") : ""
            }
          >
            {timeSlots.map((slot, index) => (
              <option key={index} value={slot.format("YYYY-MM-DD hh:mm A")}>
                {slot.format("hh:mm A")}
              </option>
            ))}
          </select>
        </div>
      </div>

      <br />
    </div>
  );
};

export default TimeDisplay;
