
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BaseURL } from "../../Helper/config";
import { toast } from "react-hot-toast";
import { Table } from "react-bootstrap";
import Swal from "sweetalert2";
import PrintModal from "../Common/Print/PrintComponent";


const PaymentSuccess = () => {
  const [order, setOrder] = useState({});
  const [showPrintModal, setShowPrintModal] = useState(false);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const transactionID = query.get("transactionID");
  const status = query.get("status");

  useEffect(() => {
    fetch(`${BaseURL}/orders-by-transaction-id/${transactionID}`)
      .then((res) => res.json())
      .then((data) => setOrder(data.data));
  }, [transactionID]);

  useEffect(() => {
    if (status === "cancel") {
      Swal.fire({
        title: "",
        text: "Payment Cancelled",
        icon: "error",
      });
    }
  }, [status]);

  useEffect(() => {
    if (order) {
      toast.success("Order Successful!", {
        position: "bottom-center",
      });
    } else {
      toast.error("Something went wrong!", {
        position: "bottom-center",
      });
    }
  }, [order]);

  const tableContent = `
    <html>
      <head>
        <title>Print Order</title>
        <style>
          body { margin: 0; padding: 20px; }
          table { width: 100%; border-collapse: collapse; }
          th, td { border: 1px solid #000; padding: 8px; text-align: left; }
          th { background-color: #f4f4f4; }
        </style>
      </head>
      <body>
        <h2>Congratulations!!! 👌 Successfully Paid</h2>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Quantity</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            ${order[0]?.orderItems?.map((item, index) => `
              <tr>
                <td>${index + 1}</td>
                <td>${item?.foodName}</td>
                <td>${item?.foodQty}</td>
                <td>${item?.foodPrice}</td>
              </tr>
            `).join('')}
            <tr>
              <td colspan="3"><h4>Total</h4></td>
              <td>${order[0]?.orderTotalAmount}</td>
            </tr>
          </tbody>
        </table>
      </body>
    </html>
  `;

  const handlePrintModalOpen = () => setShowPrintModal(true);
  const handlePrintModalClose = () => setShowPrintModal(false);

  return (
    <div style={{marginTop:'200px'}}>
      {order[0]?.orderStatus === "Payment Completed" ? (
        <div style={{ margin: "20px" }}>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>delivery Fee</th>
              </tr>
            </thead>
            <tbody>
            
              { order && order[0]?.orderItems?.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item?.foodName}</td>
                  <td>{item?.foodQty}</td>
                  <td>{item?.foodPrice}</td>
                  <td>{item?.deliveryCharge}</td>
                </tr>
              ))}
              <tr>
                <td colSpan={3}>
                  <h4>Total</h4>
                </td>
                <td>{order[0]?.orderTotalAmount}</td>
              </tr>
            </tbody>
          </Table>
          <button className="btn btn-primary" onClick={handlePrintModalOpen}>
            Print
          </button>
          {/* <PrintModal
            show={showPrintModal}
            handleClose={handlePrintModalClose}
            content={tableContent}
          /> */}
        </div>
      ) : (
        <h2>Something went wrong</h2>
      )}
    </div>
  );
};

export default PaymentSuccess;
