import { createSlice } from "@reduxjs/toolkit";

 const SellerSilce = createSlice({
    name:'seller',
    initialState:{
        limitedSellerList:[],
        getSellerInfo:[],
        getSellerFoodData:[]
    },
    reducers:{
        setLimitedSellerList:(state,action)=>{
            state.limitedSellerList=action.payload;

        },
        getSellerInfo:(state,action)=>{
            state.getSellerInfo=action.payload
        },
        getSellerFood:(state,action)=>{
            state.getSellerFoodData=action.payload
        }

    }
 })

 export default SellerSilce.reducer
 export const {setLimitedSellerList,getSellerInfo,getSellerFood} =SellerSilce.actions